export default function Music() {
  return (
    <div className='container'>
      <p>
       Music
      </p>
      
    </div>
  );
}
