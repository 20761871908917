export default function Contact() {
  return (
    <div className='container'>
      <p>
        Contact
      </p>
      
    </div>
  );
}
